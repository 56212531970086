import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import Layout from '../components/layout/Layout'
import ProductDetails from '../components/ProductDetails'
import TagLabel from '../components/TagLabel'

import PageData from '../data/vizirail-data.yaml'

const Vizirail = () => (
  <Layout>   
    <section id="vizirail-menu">
      <div className="container mx-auto p-8 pt-16 text-center">
        <h1 className="text-primary-500 font-semibold tracking-wide uppercase">Products & Services</h1>
        <h1 className="md:text-4xl text-3xl font-semibold tracking-tight justify-center mt-2">{PageData.title}</h1>
        <p className="md:text-lg font-light my-4">{PageData.overview}</p>
        <div className="container grid md:grid-cols-3 grid-cols-1 mx-auto text-center mt-8">
          {!PageData.modules ? null : 
            PageData.modules.map((module, i) => {
              return (
                <div className="grid-cols flex">
                  <AnchorLink to={`#${module.id}`} title={`${module.menu}`} className="m-auto">
                    <div className="px-6 py-2">{module.menu}</div>
                  </AnchorLink>
                </div>
              )
          })}
        </div>
      </div>
    </section>

    {!PageData.modules
      ? null
      : PageData.modules.map((module, i) => {
          return (
            <ProductDetails
              id={module.id}
              image={module.image}
              title={module.title}
              reverse={i % 2}
            >
              {!module.paragraphs ? null : 
                module.paragraphs.map(paragraph => {
                  return <p>{paragraph}</p>
              })}
              {!module.tags ? null : (
                <div className="pb-2">
                  {module.tags.map((feature) => {
                    return <TagLabel children={feature} />
                  })}     
                </div>
              )}
            </ProductDetails>
          )
        })}

    <section id="contact-us" className="md:px-0 px-4 bg-transparent">
      <div className="container flex mx-auto">
        <span className="text-lg space-x-2 mx-auto">
          <FontAwesomeIcon icon={faCaretUp} />
          <Link to="/contact">Enquire today for more information.</Link>
          <FontAwesomeIcon icon={faCaretUp} />
        </span>
      </div>
    </section>
  </Layout>
)
export default Vizirail